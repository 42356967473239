<template>
  <div>
    <div style="font-size: 30px;">HM根据页面分类ID获取商品ID</div>
    <div style="width: 100%;display: flex;flex-direction: column;margin-top: 60px;align-items: center;">
      请输入分类ID
      <el-input v-model="categoryId" placeholder="请输入分类ID" style="width: 20%;margin-top: 30px;margin-bottom: 20px;"></el-input>
      请输入爬取页数
      <el-input-number v-model="maxsize" @change="handleChange" :min="1" label="需要爬取页数" style="margin-top: 30px;width: 20%;"></el-input-number>
      <el-button type="danger" plain @click="handleTestResult()" style="margin-top: 30px;width: 20%;">进行爬取</el-button>
      <el-input
  type="textarea"
  :rows="15"
  placeholder="请输入内容"
  style="margin-top: 30px;width: 60%;"
  v-model="result">
</el-input>
    </div>
    <div v-if="loading">正在生成中...</div>
    <div style="font-weight: 800;margin-top: 40px;">商品预览</div>
    <div style="display: flex;align-items: center;flex-wrap: wrap;width: 100%;">
    <div  v-for="(item,index) in dataList" :key="index" style="border-radius: 10px; padding: 20px; margin-left: 10px; width: 200px; display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <img :src="item.still_life" alt="image" style="width: 100%;border-radius: 10px;"/>
        <div>{{item.name }}</div>
        <div>{{item.sku }}</div>
    </div>
    </div>
  </div>

   

</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  components: {
  },
data(){
  return {
    result:'',
    categoryId:'',
    maxsize:100,
    dataList:[],
    loading:false
  }
},
methods:{
  handleChange(e){
    console.log(e)
  },
  handleTestResult() {
    if(this.categoryId ==''){
      this.$message({
          message: '输入不能为空',
          type: 'warning'
        });
        return;
    }
    this.loading = true
    this.dataList = []
    axios.get(`https://hmranking.applesay.cn/api-product-hm-category/product/sortByCategory?distinctId=1234&category=${this.categoryId}&filters%5Bfrom%5D=0&filters%5Bsize%5D=${this.maxsize}&filters%5Bsort%5D=stock&security=1&channel=hm-Applesay-search`)
    .then(response => {
        console.log(response)
        let list = response.data.items
        this.dataList = list
        let productids = list.map(e=>e.sku).join(",")
        this.result = productids
        this.loading = false
    })
    .catch(error => {
      console.log(error);
      this.loading = false
    });
  },

     
}
}

</script>
